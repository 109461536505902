/* slider section */

.slider_section {
    height: calc(100vh - 110px);
  }
  
 
  .slider_section .slider_box {
    display: flex;
    position: relative;
    height: calc(100vh - 115px);
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    
  }
  
  .slider_section .slider_box .fixed_company-detail {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-50%, -50%);
  }
  
  .slider_section .slider_box .fixed_company-detail p {
    width: 12px;
    text-align: center;
    word-break: break-word;
    color: #fff;
    text-transform: uppercase;
  }
  
  .slider_section .slider_box .slider-detail {
    color: #fff;
    width: 60%;
    margin-top: 14%;
    margin-left: 20%;
  }
  
  .slider_section .slider_box .slider-detail .slider_detail-heading h2 {
    font-weight: 100;
    text-transform: uppercase;
  }
  
  .slider_section .slider_box .slider-detail .slider_detail-heading h1 {
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .slider_section .slider_box .slider-detail .slider_detail-text {
    width: 55%;
    color: #f8fafd;
  }
  
  .slider_section .slider_box .slider-detail .slider_detail-btn {
    display: flex;
  }
  
  .slider_section .slider_box .slider-detail .slider_detail-btn a {
    margin-top: 28px;
    padding: 16px 20px;
    background-color: #2561db;
    color: #fff;
  }
  
  /* end slider section */