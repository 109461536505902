body {
  font-family: "Open Sans", sans-serif;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
}

/*header section*/

.header_section {
  font-family: "Roboto", sans-serif;
  background-color: #2c308c;
  color: #fff;
  min-height: 90px;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {
  color: #c1c3c4;
  padding-left: 12px;
  padding-right: 12px;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link.active {
  color: #fff;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.custom_nav-container .navbar-toggler {
  border-color: #fff;
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  background-image: url(../images/menu.png);
}

.custom_nav-container .nav_search-btn {
  background-image: url(../images/search-icon.png);

  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: 7px;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}

 .navbar {
  padding: 0;
} 

.nav-link:hover {
  color: #fefefe;
}

.logo_box {
  width: 105px;
}

.logo_box img {
  width: 100%;
}

.call_to_btn {
  display: inline-block;
  color: #fefefe;
  padding: 10px 35px;
  background-color: #f6bb08;
  margin-top: 5%;
  margin-bottom: 20%;
  border-radius: 0;
}

/*end header section*/



/* welcome section */
.welcome_section {
}

.welcome_section .col-md-6 {
  padding: 0 4%;
}

.welcome_detail {
  width: 75%;
}

.welcome_detail h2 {
  text-transform: uppercase;
  color: #0c0b09;
  font-weight: bold;
}

.welcome_detail h3 {
  text-transform: uppercase;
  color: #254a93;
  position: relative;
}

.welcome_detail h3::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 1.5px;
  width: 140px;
  background-color: #36bb25;
}

.welcome_detail h2,
.welcome_detail p,
.welcome_detail .welcome_detail-btn {
  margin-top: 25px;
}

.welcome_section .welcome_detail-btn a,
.welcome_section .welcome_detail-btn a:hover {
  text-transform: uppercase;

  color: #0c0b09;
}

.welcome_section .welcome_detail-btn a img {
  width: 25px;
}

/* end welcome section */

/* service section */

.service_section .container {
  padding: 0 50px;
}

.service_detail h2 {
  text-transform: uppercase;
  color: #0c0b09;
  font-weight: bold;
}

.service_detail h3 {
  text-transform: uppercase;
  color: #254a93;
  position: relative;
}

.service_detail h3::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 1.5px;
  width: 45px;
  background-color: #36bb25;
}

.service_section .service_img-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}

.service_section .service_img-container .service_img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  height: 325px;
  background-size: cover;
}

.service_img-container .service_img-box.i-box-1 {
  width: 60%;
  background-image: url(../images/s-1.jpg);
}

.service_img-container .service_img-box.i-box-2 {
  width: 40%;
  background-image: url(../images/s-2.jpg);
}

.service_img-container .service_img-box.i-box-3 {
  width: 40%;
  background-image: url(../images/s-3.jpg);
}

.service_img-container .service_img-box.i-box-4 {
  width: 60%;
  background-image: url(../images/s-4.jpg);
}

.service_img-container .service_img-box.i-box-5 {
  width: 60%;
  background-image: url(../images/s-5.jpg);
}

.service_img-container .service_img-box.i-box-6 {
  width: 40%;
  background-image: url(../images/s-6.jpg);
}

.service_img-container .service_img-box.i-box-7 {
  width: 40%;
  background-image: url(../images/s-7.jpg);
}

.service_img-container .service_img-box.i-box-8 {
  width: 60%;
  background-image: url(../images/s-8.jpg);
}

.service_section .service_img-container a,
.service_section .service_img-container a:hover {
  color: #fff;
  text-transform: uppercase;
  padding: 15px 40px;
}

.service_section .service_img-container .service_img-box:hover {
  cursor: pointer;
}

.service_section .service_img-container .service_img-box:hover a {
  background-color: #2561db;
}

.service_btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.service_btn a,
.service_btn a:hover {
  text-transform: uppercase;

  color: #0c0b09;
}

.service_btn a img {
  width: 25px;
}

/* end service section */

/* security section */
.security_section {
}

.security_section h2 {
  text-transform: uppercase;
  color: #0c0b09;
  font-weight: bold;
  text-align: center;
  margin-bottom: 35px;
}

.security_section h3 {
  text-transform: uppercase;
  color: #254a93;
  position: relative;
  text-align: center;
}

.security_section h3::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  height: 1.5px;
  width: 140px;
  background-color: #36bb25;
}

.security_section .security_container {
  width: 85%;
  margin: 0 auto;
}

.security_section .security_img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  width: 300px;
  height: 490px;
  margin: 0 auto;
  z-index: 999;
}

.security_section .security_img-box.i-box-1 {
  background-image: url(../images/security-1.jpg);
}

.security_section .security_img-box.i-box-2 {
  background-image: url(../images/security-2.jpg);
}

.security_section .security_img-box.i-box-3 {
  background-image: url(../images/security-3.jpg);
}

.security_section .security_img-box.i-box-4 {
  background-image: url(../images/security-4.jpg);
}

.security_section .security_img-box.i-box-5 {
  background-image: url(../images/security-5.jpg);
}

.security_section .security_img-box a {
  display: none;
  opacity: 0;
  text-transform: uppercase;
  padding: 8px 12px;
  border: 1.5px solid #f0f0f0;
  color: #fff;
}

.security_section .security_img-box:hover a {
  display: inline-block;
  opacity: 1;
}

.security_btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.security_btn a,
.security_btn a:hover {
  text-transform: uppercase;

  color: #0c0b09;
}

.security_btn a img {
  width: 25px;
}

.owl-carousel .owl-nav,
.owl-carousel .owl-nav.disabled {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 0;
}

.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  background-color: #254a93;
  width: 70px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: 50%;
  transform: translateY(-50%);
}

.owl-carousel .owl-nav .owl-next {
  right: -25px;
  background-image: url(../images/right-arrow.png);
}

.owl-carousel .owl-nav .owl-prev {
  left: -25px;
  background-image: url(../images/left-arrow.png);
}

.slick-next:before, .slick-prev:before{
  color:#2c308c !important
}

/* end security section */

.story_section {
  padding: 0 4%;
}

.story_section .container-fluid {
  -webkit-box-shadow: 1px -1px 24px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px -1px 24px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 1px -1px 24px -13px rgba(0, 0, 0, 0.75);
}

.story_section .col-md-6 {
  padding: 0;
  display: flex;
  align-items: center;
  background-color: #fcfcfc;
}

.story_detail {
  margin-left: 40px;
}

.story_detail h2 {
  text-transform: uppercase;
  color: #0c0b09;
  font-weight: bold;
}

.story_detail h3 {
  text-transform: uppercase;
  color: #254a93;

  margin: 0;
}

.story_detail {
  width: 85%;
}

.story_detail .story_detail-btn {
  margin-top: 25px;
}

.story_section .story_detail-btn a,
.story_section .story_detail-btn a:hover {
  text-transform: uppercase;

  color: #0c0b09;
}

.story_section .story_detail-btn a img {
  width: 25px;
}

.contact_form-container {
  background-image: url(../images/guards.jpg);
}

.contact_form-container form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact_form-container .contact_box {
  background-color: #fff;
  padding: 35px 40px;
  width: 50%;
  height: 60%;
  margin: 100px 50px;
}

.contact_form-container form input,
.contact_form-container form input:focus,
.contact_form-container form input:active {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #00cc99;
  width: 85%;
  margin-top: 12px;
  outline: none;
}

.contact_form-container form button {
  border: none;
  background-color: #254a93;
  color: #fff;
  padding: 8px 20px;
  margin-top: 15px;
}

.contact_section .row .col-md-6 {
  padding: 0;
}

/* end contact section */

/* info section */
.info_section {
  background-color: #000202;
  font-family: "Roboto", sans-serif;
}

.info_section .info_detail {
  display: flex;
  align-items: center;
}

.info_section .info_detail p {
  color: #fff;
}

.address_link-container {
  display: block;
}

.address_link-container a {
  display: flex;
  color: #fff;
  margin: 15px 0;
  align-items: center;
}

.address_link-container a img {
  margin-right: 10px;
}

.info_section .news_container h3,
.info_section .address_container h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
}

.info_section .news_container {
  /* margin: 35px 0 0 0; */
}

.info_section .news_container form input {
  border: none;
  background-color: #fff;
  width: 70%;
  padding: 7px 10px;
}

.info_section .news_container form button {
  border: none;
  background-color: #254a93;
  color: #fff;
  font-size: 14px;
  padding: 8px 15px;
  margin-top: 15px;
  text-transform: uppercase;
}

.info_section .news_container .social_container {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

/* end info section */

/* footer section*/

.footer_section {
  background-color: #254a93;
  padding: 20px 0;
  font-family: "Roboto", sans-serif;
}

.footer_section p {
  color: #fff;
  margin: 0;
  text-align: center;
}

.footer_section a {
  color: #fff;
}

/* end footer section*/

.other-pg-heading{
  padding: 50px;
  text-align: center;
  background-color: #dbdfe1;
  color: #2c308c;
  margin: 21px 0;
  text-transform: uppercase;
}

.err-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}


/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__canvas {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 10px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 950px;
  overflow: auto;
}


.home-brochure .button {
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 15px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.home-brochure .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.home-brochure .button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.home-brochure  .button:hover span {
  padding-right: 25px;
}

.home-brochure  .button:hover span:after {
  opacity: 1;
  right: 0;
}

.list-group-flush>.list-group-item{
  background-color: transparent !important;
}

.accordion .card-header:after {
  font-family: 'FontAwesome';  
  content: "\f068";
  float: right; 
}
.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067"; 
}

